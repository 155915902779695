@import 'styles/import';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: vvw(620);
  height: vvw(1120);

  background: #fff;

  margin: vvw(24) 0;

  .content_wrapper {
    @include flexColumnCenter;
    justify-content: space-between;

    flex: 1;
    width: 100%;
    height: 100%;

    .detail_wrapper {
      width: 100%;
      height: 100%;
      max-height: calc(vvw(328) + vvw(32));

      display: flex;
      flex-direction: column;

      padding: vvw(24) vvw(16) vvw(24) 0;

      .details {
        height: vvw(280);
        overflow-y: auto;

        padding-left: vvw(60);
        padding-right: vvw(36);

        display: flex;
        flex-direction: column;
        // gap: vvw(24);

        &::-webkit-scrollbar {
          width: vvw(6);
        }

        &::-webkit-scrollbar-thumb {
          border: vvw(6) solid $gray-cc;
          background-color: none;
          background-clip: padding-box;
        }
      }
    }
  }

  .buttons_wrapper {
    display: flex;
    width: 100%;
  }

  // 정방형
  // @media all and #{$square} {
  //   width: vvw(500);

  //   .content_wrapper {
  //     .detail_wrapper {
  //       height: vvw(280);
  //       padding: vvw(24) vvw(16) vvw(24) 0;
  //     }
  //   }
  // }
}

// 가로형
@media all and #{$horizontal} {
  .selection {
    margin-top: 0;
  }

  .wrapper {
    width: calc(hvw(785) + hvw(592));
    height: hvw(604);

    gap: 0;

    flex-direction: row;

    .content_wrapper {
      width: hvw(592);
      height: hvw(604);

      background-color: $white;

      .detail_wrapper {
        // background: red;
        width: 100%;
        // max-height: auto;
        max-height: hvw(328);
        height: auto;
        min-height: hvw(250);

        display: flex;
        flex-direction: column;

        padding: hvw(24) hvw(16) hvw(24) 0;

        .details {
          max-height: hvw(280);

          overflow-x: hidden;
          overflow-y: auto;

          padding-left: hvw(60);
          padding-right: hvw(36);

          &.details::-webkit-scrollbar {
            width: hvw(6);
            height: hvw(4);
          }

          &.details::-webkit-scrollbar-thumb {
            border: hvw(6) solid $gray-cc;
          }
        }
      }
    }
  }
}
