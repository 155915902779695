@import 'styles/import';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.top_button {
  z-index: 100;
  right: 20px;
  bottom: 20px;
  position: absolute;
  width: 60px;
  height: 60px;

  background-color: $main-black;

  @include flexCenter;

  .icon {
    width: hvw(28);
    height: hvw(28);

    img {
      height: 100%;
      color: #fff;
    }
  }
}
