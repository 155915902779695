@import 'styles/import';

.desc {
  width: 100%;

  white-space: break-spaces;
  display: flex;
  align-items: flex-start;
  padding: vvw(20);
  margin-bottom: vvw(24);

  background-color: $gray-f9;

  font-weight: 400;
  font-size: vvw(16);
  line-height: vvw(24);

  text-align: left;

  // 가로형
  @media all and #{$horizontal} {
    width: hvw(474);

    padding: hvw(20);
    font-size: hvw(16);
    line-height: hvw(24);
    margin-bottom: hvw(24);
  }

  // 정방형
  // @media all and #{$square} {
  //   width: vvw(380);

  //   padding: vvw(16);
  // }
}
