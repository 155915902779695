@import 'styles/import';

.detail_image {
  @include flexCenter;
  width: 100%;
  height: vvw(480);

  background-color: $white;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &.has_image {
      padding: vvw(15);
    }
  }

  // 가로형
  @media all and #{$horizontal} {
    width: hvw(785);
    height: hvw(604);
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      &.has_image {
        padding: hvw(15);
      }
    }
  }
}
