@import 'styles/import';

.container {
  z-index: 2000;

  background: rgba(0, 0, 0, 0.7);

  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // position: absolute;

    // top: 50%;
    // left: 50%;

    // transform: translate(-50%, -50%);
  }

  .close_btn {
    position: absolute;

    // bottom: calc(vvw(-36) + vvw(-96));
    bottom: vvw(138);

    display: flex;
    justify-content: center;
    align-items: center;

    width: vvw(622);
    height: vvw(96);

    gap: vvw(6);
    flex-shrink: 0;

    border: vvw(1) solid rgba(255, 255, 255, 0.5);
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);

    .icon_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      width: vvw(40);
      height: vvw(40);

      .close_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        width: vvw(20);
        height: vvw(20);

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    > span {
      color: #fff;
      text-align: center;
      font-size: vvw(24);
      font-style: normal;
      font-weight: 600;
      line-height: vvw(24);
      letter-spacing: -0.336px;
      text-transform: capitalize;
    }
  }

  @media all and #{$horizontal} {
    .close_btn {
      // bottom: calc((hvw(-30)) + hvw(-96));
      bottom: hvw(30);

      width: hvw(622);
      height: hvw(96);

      gap: hvw(6);

      border: hvw(1) solid rgba(255, 255, 255, 0.5);
      background: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(5px);

      .icon_wrapper {
        width: hvw(40);
        height: hvw(40);

        .close_icon {
          width: hvw(20);
          height: hvw(20);
        }
      }

      > span {
        font-size: hvw(24);
        line-height: hvw(24);
      }
    }
  }
}

.web_container {
  z-index: 2000;

  background: rgba(0, 0, 0, 0.7);

  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media all and #{$small} {
    .close_btn {
      height: 48px !important;
      width: 522px !important;
    }
  }

  @media all and #{$medium} {
    .close_btn {
      height: 48px !important;
      width: 522px !important;
    }
    > span {
      color: #fff;
      text-align: center;
      font-size: 18px !important;
      font-style: normal;
      font-weight: 600;

      letter-spacing: -0.336px;
      text-transform: capitalize;
    }
  }

  .close_btn {
    position: absolute;

    // bottom: calc(vvw(-36) + vvw(-96));
    bottom: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 622px;
    height: 96px;

    gap: 6px;
    flex-shrink: 0;

    border: hvw(1) solid rgba(255, 255, 255, 0.5);
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);

    .icon_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      width: 40px;
      height: 40px;
      .close_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        width: 20px;
        height: 20px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    > span {
      color: #fff;
      text-align: center;
      font-size: 24px;
      line-height: 24px;
      font-style: normal;
      font-weight: 600;

      letter-spacing: -0.336px;
      text-transform: capitalize;
    }
  }
}
